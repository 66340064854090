import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { css, useTheme } from '@emotion/react'
import { Yes } from '@dfds-ui/icons/system'
import { FeatureList, Headline } from '@dfds-ui/react-components'

import { FlexCard } from '../Card'
import { gridMap } from '../../utils'
import { AspectImage, ImageSlider } from '../Image'
import { Markdown } from '../Markdown'

const ExperienceCard = (props) => {
  const {
    title,
    text,
    numberOfPassengersPerCabinText,
    numberOfBedsInTheCabinText,
    experienceFeaturesCollection,
    imagesCollection,
    index,
  } = props
  const { t } = useTranslation()
  const features = [
    t(`EXPERIENCE-CARD-${numberOfPassengersPerCabinText}-GUESTS-IN-THE-CABIN`),
    t(`EXPERIENCE-CARD-${numberOfBedsInTheCabinText}-BEDS-IN-THE-CABIN`),
  ].concat(
    experienceFeaturesCollection.items.map((item) => {
      return item.description
    }),
  )
  const themeContext = useTheme()

  return (
    <FlexCard
      width={gridMap['full-width']}
      mediaWidth={{ md: 8 }}
      reverse={index && index % 2}
      media={
        imagesCollection && imagesCollection.items.length > 1 ? (
          <ImageSlider
            imageCollection={imagesCollection.items}
            aspectRatio={'3/2'}
          />
        ) : imagesCollection && imagesCollection.items.length === 1 ? (
          <AspectImage {...imagesCollection.items[0]} aspectRatio={'3/2'} />
        ) : null
      }
    >
      <Headline as={'h2'}>{title}</Headline>
      <Markdown source={text} />
      <FeatureList
        as={'ul'}
        icon={<Yes width="24" height="24" />}
        items={features}
        iconColor={themeContext['icon-fill']}
        css={css`
          padding-left: 0;
        `}
      />
    </FlexCard>
  )
}

export const ExperienceCardContentFragment = graphql`
  fragment ExperienceCardContent on contentful_ExperienceCardContent {
    __typename
    title
    text
    numberOfPassengersPerCabinText
    numberOfBedsInTheCabinText
    experienceFeaturesCollection(limit: 3) {
      items {
        description
      }
    }
    imagesCollection(limit: 6) {
      items {
        ...Asset
      }
    }
  }
`

export default ExperienceCard
